import React from 'react';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { HiddenSettings } from 'components/template-designer/types/dynamicLayer.type';
import Translation from 'components/data/Translation';
import TextField from 'components/ui-components-v2/TextField';
import InputWrapper from 'components/ui-components-cape/InputWrapper';
import '../styles/main.scss';

const ParseValueSettingsForm = (): JSX.Element | null => {
    const { settings, activeInput, activeInputLayer, updateInputSettings } = useDynamicLayers<HiddenSettings>();

    if (!activeInput || !activeInputLayer || settings.type !== 'hidden') return null;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form">
            <InputWrapper label={Translation.get('dynamicLayers.labels.hidden', 'template-designer')}>
                <TextField value={settings.parseValue} onChange={(event) => updateInputSettings('parseValue', event.target.value)} fullWidth size="medium" />
            </InputWrapper>
        </div>
    );
};

export { ParseValueSettingsForm };
