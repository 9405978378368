import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import { AssetSelectorSettings, DynamicLayerInput } from 'components/template-designer/types/dynamicLayer.type';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import Src from 'components/template-designer/types/src.type';
import { FeatureHelpers } from 'components/template-designer/helpers/features.helpers';
import { Features } from 'components/template-designer/types/features.type';
import AssetGalleryInput from 'components/input/AssetGalleryInput';
import { SwitchSetting } from '../inputs/switch-setting';
import '../styles/predefined-image-settings-form.scss';
import '../styles/main.scss';

interface Props {
    updateSettings?: InputAttribute<AssetSelectorSettings>['updateSettings'];
    attribute: DynamicLayerInput['attribute'];
    disableIndex?: number;
}

interface PredefinedImageProps {
    source: AssetSelectorSettings['sourceData'][0];
    onDelete: () => void;
    deleteDisabled?: boolean;
    onEdit: () => void;
    onEditTitle: (title: string) => void;
    editable: boolean;
    disableEdit: () => void;
}

const PredefinedImage = ({ source, onDelete, deleteDisabled, onEdit, onEditTitle, editable, disableEdit }: PredefinedImageProps) => {
    const title = useRef<HTMLInputElement>(null);
    const originalTitle = useRef(source.title);

    /**
     * Focuses the title input when the component is in edit mode.
     */
    useEffect(() => {
        if (!editable) return;
        title.current?.select();
    }, [editable]);

    /**
     * Handles the keydown event for the title input.
     * @param event - The keydown event.
     */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // If the user presses enter, we want to disable the edit mode.
        if (event.key === 'Enter') {
            originalTitle.current = source.title;
            disableEdit();
        }

        // If the user presses escape, we want to revert the title back to the original title and disable the edit mode.
        if (event.key === 'Escape') {
            onEditTitle(originalTitle.current);
            disableEdit();
        }
    };

    return (
        <div className="template-designer__dynamic-layer-edit__predefined-image">
            <div
                className={classNames('template-designer__dynamic-layer-edit__predefined-image__item', {
                    ['template-designer__dynamic-layer-edit__predefined-image__item--hover']: !deleteDisabled
                })}>
                {!deleteDisabled && (
                    <IconButton className="template-designer__dynamic-layer-edit__predefined-image__delete" onClick={onDelete}>
                        <Icon>delete</Icon>
                    </IconButton>
                )}
                <div className="template-designer__dynamic-layer-edit__predefined-image__overlay" />
                <img src={source.url} alt={source.title} className="template-designer__dynamic-layer-edit__predefined-image__image" />
            </div>
            {editable ? (
                <input
                    className="template-designer__dynamic-layer-edit__predefined-image__title template-designer__dynamic-layer-edit__predefined-image__title--edit"
                    ref={title}
                    value={source.title}
                    onChange={(event) => onEditTitle(event.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={disableEdit}
                />
            ) : (
                <div
                    className={classNames('template-designer__dynamic-layer-edit__predefined-image__title', {
                        ['template-designer__dynamic-layer-edit__predefined-image__title--empty']: !source.title
                    })}
                    onClick={onEdit}>
                    {source.title || Translation.get('dynamicLayers.layerEdit.editForms.forms.predefinedImageSettings.emptyTitle', 'template-designer')}
                </div>
            )}
        </div>
    );
};

const PredefinedImagesSettingsForm = ({ updateSettings, attribute, disableIndex }: Props): JSX.Element | null => {
    const [editaleSource, setEditableSource] = useState<AssetSelectorSettings['sourceData'][0]['id'] | null>(null);

    const { settings, activeInput, updateActiveInput, activeInputLayer, updateInputSettings } = useDynamicLayers<AssetSelectorSettings>();

    const templateValue = useMemo(
        () => activeInputLayer && DynamicLayerHelpers.getGeneralTemplateValue(attribute, settings, activeInputLayer),
        [activeInputLayer]
    ) as Src | undefined;

    /**
     * Updates the settings when the templateValue changes.
     */
    useEffect(() => {
        if (!activeInput || !activeInputLayer) return;
        if (updateSettings && settings.sourceData && settings.sourceData?.[0]?.url !== templateValue?.url) {
            const newSettings = updateSettings(activeInputLayer, attribute, settings);
            updateActiveInput('settings', newSettings);
        }
    }, [templateValue, settings.type]);

    /**
     * Deletes an image from the list of sourceData.
     * @param id - The id of the image to delete.
     */
    const handleDelete = (id: AssetSelectorSettings['sourceData'][0]['id']) => {
        const newSourceData = cloneDeep(settings.sourceData).filter((source) => source.id !== id);
        updateInputSettings('sourceData', newSourceData);
    };

    if (!activeInput || settings.type !== 'assetSelector') return null;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form template-designer__dynamic-layer-edit__predefined-image-settings-form">
            <div className="template-designer__dynamic-layer-edit__predefined-image-settings-form__grid">
                {settings.sourceData.map((source, index) => (
                    <PredefinedImage
                        key={source.id}
                        source={source}
                        onDelete={() => handleDelete(source.id)}
                        deleteDisabled={disableIndex === index && templateValue?.url === source.url} // Disable the delete button if the image is the same as the one in the design.
                        onEdit={() => setEditableSource(source.id)}
                        onEditTitle={(title) => {
                            const newSourceData = cloneDeep(settings.sourceData);
                            const sourceIndex = newSourceData.findIndex((source) => source.id === editaleSource);
                            newSourceData[sourceIndex].title = title;
                            updateInputSettings('sourceData', newSourceData);
                        }}
                        editable={editaleSource === source.id}
                        disableEdit={() => setEditableSource(null)}
                    />
                ))}
                <AssetGalleryInput
                    multiple={false}
                    useCropper
                    useCompressor
                    useCustomCompressor
                    canSkipCropper
                    canUseVoiceOverGenerator
                    canSkipCompressor
                    useTrimmer
                    canSkipTrimmer
                    canUseContentSpace
                    canRemoveBackground
                    canRemoveObjects
                    canUseAiContent
                    canUseImageToVideo
                    canUseUrl={FeatureHelpers.hasFeature(Features.URL_SELECTOR)}
                    fileType="image"
                    value={{}} // To always start from scratch when opening the dialog.
                    mode="free"
                    onMutation={(data, type) => {
                        // If the type is sourceData, that means it will be added to the list of resources. We don't want to do anything with it.
                        if (type === 'sourceData') return;

                        const newSourceData = cloneDeep(settings.sourceData);
                        newSourceData.push({
                            id: generateKey(),
                            title: data.title,
                            url: data.url
                        });
                        updateInputSettings('sourceData', newSourceData);
                    }}>
                    {({ onClickOpen }: { onClickOpen: () => void }) => (
                        <div className="template-designer__dynamic-layer-edit__predefined-image">
                            <div className="template-designer__dynamic-layer-edit__predefined-image__add" onClick={onClickOpen}>
                                <Icon className="template-designer__dynamic-layer-edit__predefined-image__add-icon">add</Icon>
                            </div>

                            <div className="template-designer__dynamic-layer-edit__predefined-image__add-title">Add image</div>
                        </div>
                    )}
                </AssetGalleryInput>
            </div>
            <SwitchSetting
                label={Translation.get('dynamicLayers.layerEdit.editForms.forms.predefinedImageSettings.showTitles', 'template-designer')}
                icon="text_fields"
                value={settings.showTitles}
                update={(newValue) => updateInputSettings('showTitles', newValue)}
            />
        </div>
    );
};

export { PredefinedImagesSettingsForm };
