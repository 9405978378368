import React from 'react';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { SliderSettings } from 'components/template-designer/types/dynamicLayer.type';
import Translation from 'components/data/Translation';
import InputWrapper from 'components/ui-components-cape/InputWrapper';
import { NumberInputField } from 'components/template-designer/components/ui-components/number-input-field';
import { SwitchSetting } from '../inputs/switch-setting';
import '../styles/main.scss';

interface Props {
    unit?: '%' | 'deg' | 'px' | 'em' | 'ms';
    allowNegative?: boolean;
    min?: number;
    max?: number;
}

const NumberSettingsForm = ({ unit, allowNegative, min, max }: Props): JSX.Element | null => {
    const { settings, activeInput, updateInputSettings } = useDynamicLayers<SliderSettings>();

    if (!activeInput || (settings.type !== 'slider' && settings.type !== 'number')) return null;

    return (
        <div className="template-designer__dynamic-layer-edit__slider-settings-form template-designer__dynamic-layer-edit__settings-form">
            <div className="template-designer__dynamic-layer-edit__slider-settings-form__boundaries">
                <InputWrapper
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.slider.min', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.numberSettings.minTooltip', 'template-designer')}>
                    <NumberInputField
                        min={(() => {
                            if (min !== undefined) return min;
                            if (allowNegative) return undefined;
                            return 0;
                        })()}
                        max={settings.max - 1}
                        value={settings.min}
                        size="medium"
                        onChange={(newValue) => updateInputSettings('min', newValue)}
                        adornment={unit}
                    />
                </InputWrapper>
                <InputWrapper
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.slider.max', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.numberSettings.maxTooltip', 'template-designer')}>
                    <NumberInputField
                        value={settings.max}
                        onChange={(newValue) => updateInputSettings('max', newValue)}
                        adornment={unit}
                        min={settings.min + 1}
                        max={max}
                    />
                </InputWrapper>
                <InputWrapper
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.slider.stepCount', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.numberSettings.stepCountTooltip', 'template-designer')}>
                    <NumberInputField value={settings.step} onChange={(newValue) => updateInputSettings('step', newValue)} step={1} min={1} adornment={unit} />
                </InputWrapper>
            </div>
            {settings.type === 'slider' && (
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.slider.showNumberInput', 'template-designer')}
                    value={settings.inputFieldDisplay}
                    update={(newValue) => updateInputSettings('inputFieldDisplay', newValue)}
                />
            )}
            {settings.type === 'slider' && (
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.slider.showStep', 'template-designer')}
                    value={settings.marks}
                    update={(newValue) => updateInputSettings('marks', newValue)}
                />
            )}
        </div>
    );
};

export { NumberSettingsForm };
