import React, { useEffect, useMemo } from 'react';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { DynamicLayerInput, RadioListSettings, SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import Translation from 'components/data/Translation';
import { GenericSvgIcon } from 'components/ui-components/GenericIcon';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import ToggleButton from 'components/ui-components-v2/ToggleButton';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { attributeInputs } from '../../../config/attribute-inputs';
import { SwitchSetting } from '../inputs/switch-setting';
import '../styles/main.scss';

interface Props {
    attribute: DynamicLayerInput['attribute'];
    options: {
        value: string;
        label: string;
        icon?: GenericSvgIcon | string;
    }[];
    updateSettings?: InputAttribute<SelectSettings | RadioListSettings>['updateSettings'];
}

/**
 * Component to change the settings of the select and radioList with preset options
 */
const MultiSelectForm = ({ attribute, options, updateSettings }: Props): JSX.Element | null => {
    const { settings, activeInput, activeInputLayer, updateInputSettings, updateActiveInput } = useDynamicLayers<SelectSettings | RadioListSettings>();

    const templateValue = useMemo(
        () => activeInputLayer && DynamicLayerHelpers.getGeneralTemplateValue(attribute, settings, activeInputLayer),
        [activeInputLayer]
    );

    // Update the options if the template value changes and the template value is not in the options.
    useEffect(() => {
        if (!activeInput || !activeInputLayer || (settings.type !== 'select' && settings.type !== 'radioList')) return;
        if (updateSettings && !settings.options.some((option) => option.key === templateValue)) {
            const newSettings = updateSettings(activeInputLayer, attribute, settings);
            updateActiveInput('settings', newSettings);
        }
    }, [templateValue]);

    if (!activeInput || !activeInputLayer || (settings.type !== 'select' && settings.type !== 'radioList')) return null;

    const selectAttribute = attributeInputs[activeInputLayer.type].all[activeInput.attribute].settings;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form">
            {selectAttribute && 'radioList' in selectAttribute && 'select' in selectAttribute && (
                <ToggleButtonGroup
                    value={settings.type}
                    size="large"
                    fullWidth
                    color="primary"
                    onChange={(_, type) => {
                        if (!type || type === settings.type) return;

                        const newSettings = attributeInputs[activeInputLayer.type]?.all[activeInput.attribute]?.settings?.[type];
                        if (newSettings) {
                            updateActiveInput('settings', { ...newSettings, options: settings.options, label: activeInput.label });
                        }
                    }}
                    exclusive>
                    <ToggleButton value="radioList">
                        {Translation.get('dynamicLayers.layerEdit.editForms.inputTypes.radioList', 'template-designer')}
                    </ToggleButton>
                    <ToggleButton value="select">{Translation.get('dynamicLayers.layerEdit.editForms.inputTypes.select', 'template-designer')}</ToggleButton>
                </ToggleButtonGroup>
            )}

            {options.map((option) => {
                return (
                    <Tooltip
                        key={option.value}
                        disableInteractive
                        title={
                            option.value === templateValue
                                ? Translation.get('dynamicLayers.layerEdit.editForms.forms.general.optionDisabled', 'template-designer')
                                : ''
                        }>
                        <div>
                            <SwitchSetting
                                label={option.label}
                                disabled={option.value === templateValue}
                                value={!!settings.options.find((settingOption) => settingOption.key === option.value)}
                                update={(value) => {
                                    let newOptions: typeof settings.options = cloneDeep(settings.options);

                                    if (value) {
                                        newOptions.push({ key: option.value, value: option.label });
                                    } else {
                                        newOptions = newOptions.filter((filterOption) => filterOption.key !== option.value);
                                    }

                                    // Sort object based on order of options
                                    const sortedOptions: typeof settings.options = [];
                                    options.forEach((option) => {
                                        const newOption = newOptions.find((newOption) => newOption.key === option.value);
                                        if (newOption) {
                                            sortedOptions.push(newOption);
                                        }
                                    });

                                    updateInputSettings('options', sortedOptions);
                                }}
                                icon={option.icon}
                            />
                        </div>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export { MultiSelectForm };
