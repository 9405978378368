import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import GenericIcon, { GenericSvgIcon } from 'components/ui-components/GenericIcon';
import Switch from 'components/ui-components-v2/Switch';
import Tooltip from 'components/ui-components-v2/Tooltip';
import '../styles/switch-setting.scss';

export interface SwitchSettingProps {
    label: string;
    extraLabel?: React.ReactNode;
    tooltip?: string;
    disabledTooltip?: string;
    helperText?: string;
    icon?: GenericSvgIcon | string;
    value: boolean | undefined;
    update: (newValue: boolean) => void;
    children?: React.ReactNode;
    switchColor?: 'primary' | 'secondary';
    disabled?: boolean;
    inverted?: boolean;
}

const availableGenericIcons: GenericSvgIcon[] = ['dashed-line', 'wavy-line'];

const SwitchSetting = ({
    label,
    extraLabel,
    tooltip,
    disabledTooltip,
    helperText,
    icon,
    value,
    update,
    children,
    switchColor = 'primary',
    disabled = false,
    inverted = false
}: SwitchSettingProps) => {
    const isGenericIcon = (icon: SwitchSettingProps['icon']): icon is GenericSvgIcon => availableGenericIcons.includes(icon as GenericSvgIcon);

    return (
        <Tooltip title={disabledTooltip} disableInteractive>
            <div>
                <div className="template-designer__dynamic-layer-edit__switch-setting">
                    <Switch
                        disabled={disabled}
                        color={switchColor}
                        className="template-designer__dynamic-layer-edit__switch-setting__switch"
                        size="medium"
                        checked={inverted ? !value : value}
                        onChange={(event) => (inverted ? update(!event.target.checked) : update(event.target.checked))}
                    />
                    <div className="template-designer__dynamic-layer-edit__switch-setting__label-container">
                        {icon && (
                            <>
                                {isGenericIcon(icon) ? (
                                    <GenericIcon className="template-designer__dynamic-layer-edit__switch-setting__icon-svg" icon={icon} />
                                ) : (
                                    <Icon className="template-designer__dynamic-layer-edit__switch-setting__icon-span">{icon}</Icon>
                                )}
                            </>
                        )}
                        <div className="template-designer__dynamic-layer-edit__switch-setting__label-inner-container">
                            <div className="template-designer__dynamic-layer-edit__switch-setting__label">
                                {label}
                                {tooltip && (
                                    <Tooltip title={tooltip} disableInteractive>
                                        <Icon className="template-designer__dynamic-layer-edit__switch-setting__tooltip">info</Icon>
                                    </Tooltip>
                                )}
                            </div>

                            {extraLabel && extraLabel}
                        </div>
                    </div>

                    {children && <div className="template-designer__dynamic-layer-edit__switch-setting__extra">{children}</div>}
                </div>
                {helperText && <div className="template-designer__dynamic-layer-edit__switch-setting__helper-text">{helperText}</div>}
            </div>
        </Tooltip>
    );
};

export { SwitchSetting };
