import React, { useEffect, useMemo, useState } from 'react';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/dynamic-layers.context';
import { DynamicLayerInput, RadioListSettings, SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import Translation from 'components/data/Translation';
import BrandGuide from 'components/template-designer/types/brandGuide.type';
import { GoogleFonts } from 'components/template-designer/models/google-fonts';
import { webSafeFonts } from 'components/template-designer/config/fonts';
import { FontType } from 'components/template-designer/types/font.type';
import { AutocompleteImage } from 'components/template-designer/components/ui-components/autocomplete-image';
import { Autocomplete } from 'components/template-designer/components/ui-components/autocomplete';
import ToggleButton from 'components/ui-components-v2/ToggleButton';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import InputWrapper from 'components/ui-components-cape/InputWrapper';
import Select from 'components/ui-components-v2/Select';
import MenuItem from 'components/ui-components-v2/MenuItem';
import { attributeInputs } from '../../../config/attribute-inputs';
import '../styles/main.scss';

interface Props {
    attribute: DynamicLayerInput['attribute'];
    updateSettings?: InputAttribute<SelectSettings>['updateSettings'];
}

const FontFamilyForm = ({ attribute, updateSettings }: Props): JSX.Element | null => {
    const { settings, activeInput, activeInputLayer, updateInputSettings, updateActiveInput } = useDynamicLayers<SelectSettings | RadioListSettings>();
    const [fontSource, setFontSource] = useState<FontType>('brandGuide');

    const templateValue = useMemo(
        () => activeInputLayer && DynamicLayerHelpers.getGeneralTemplateValue(attribute, settings, activeInputLayer),
        [activeInputLayer]
    );

    // Update the first color if the template color value changes.
    useEffect(() => {
        if (!activeInput || !activeInputLayer || (settings.type !== 'select' && settings.type !== 'radioList')) return;
        if (updateSettings && settings.options && attribute) {
            const newSettings = updateSettings(activeInputLayer, attribute, settings);
            updateActiveInput('settings', newSettings);
        }
    }, [templateValue]);

    /**
     * Get the brand guide fonts and split them into general and brand fonts.
     */
    const brandGuideFonts = useMemo(() => {
        const brandGuide = getTemplateData<BrandGuide>('brandGuide');

        const general: { key: string; value: string }[] = [];
        const brand: { key: string; value: string }[] = [];
        let brandName = '';
        brandGuide.fonts.forEach((item) => {
            if (item.brand === 'general') {
                general.push({ key: item.key, value: item.value });
            } else {
                brandName = item.brand || '';
                brand.push({ key: item.key, value: item.value });
            }
        });

        return {
            general,
            brand,
            brandName
        };
    }, []);

    const googleFonts = useMemo(() => GoogleFonts.get().map((font) => ({ key: font.family, value: font.family })), []);
    const standardFonts = useMemo(() => webSafeFonts.map((font) => ({ key: font.value, value: font.label })), []);

    const fontSourceOptions: { standard: string; brandGuide: string; brandGuide_brand?: string; googleFonts: string } = {
        standard: Translation.get('sidebarRight.inputs.fontSourceOptions.standard', 'template-designer'),
        brandGuide: Translation.get('sidebarRight.inputs.fontSourceOptions.brandGuide', 'template-designer'),
        brandGuide_brand: Translation.get('sidebarRight.inputs.fontSourceOptions.brandGuideBrand', 'template-designer', { brand: brandGuideFonts.brandName }),
        googleFonts: Translation.get('sidebarRight.inputs.fontSourceOptions.googleFonts', 'template-designer')
    };

    const generalFontOptions = useMemo(
        () =>
            brandGuideFonts.general.map((font) => ({
                value: font.key,
                label: font.value
            })),
        []
    );
    const brandFontOptions = useMemo(
        () =>
            brandGuideFonts.brand.map((font) => ({
                value: font.key,
                label: font.value
            })),
        []
    );

    const googleFontOptions = useMemo(
        () =>
            googleFonts.map((font) => ({
                value: font.key,
                label: font.value,
                image: `${process.env.STATIC_HOSTING_URL}assets/fonts/${encodeURI(font.key)}.svg`
            })),
        []
    );
    const standardFontOptions = useMemo(
        () =>
            standardFonts.map((font) => ({
                value: font.key,
                label: font.value
            })),
        []
    );

    if (!brandGuideFonts.brand.length) {
        delete fontSourceOptions['brandGuide_brand'];
    }

    const currentFontOptions = (() => {
        if (fontSource === 'brandGuide') return generalFontOptions;
        if (fontSource === 'brandGuide_brand') return brandFontOptions;
        if (fontSource === 'googleFonts') return googleFontOptions;
        return standardFontOptions;
    })();

    /**
     * Update the available font array.
     * @param value New font
     */
    const onChangeFont = (value) => {
        if (!Array.isArray(value)) return;
        const newOptions: { key: string; value: string }[] = [];

        for (let i = 0; i < value.length; i++) {
            let found = false;
            const val = value[i].value;

            const brandFont = brandGuideFonts.brand.find((brandFont) => brandFont.key === val);
            if (brandFont) {
                newOptions.push(brandFont);
                found = true;
            }

            if (!found) {
                const generalFont = brandGuideFonts.general.find((generalFont) => generalFont.key === val);
                if (generalFont) {
                    newOptions.push(generalFont);
                    found = true;
                }
            }

            if (!found) {
                const standardFont = standardFonts.find((standardFont) => standardFont.key === val);
                if (standardFont) {
                    newOptions.push(standardFont);
                    found = true;
                }
            }

            if (!found) {
                const googleFont = googleFonts.find((googleFont) => googleFont.key === val);
                if (googleFont) {
                    newOptions.push(googleFont);
                }
            }
        }

        updateInputSettings('options', newOptions);
    };

    if (!activeInput || !activeInputLayer) return null;

    const selectAttribute = attributeInputs[activeInputLayer.type].all[activeInput.attribute].settings;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form">
            {selectAttribute && 'radioList' in selectAttribute && 'select' in selectAttribute && (
                <InputWrapper gutterBottom>
                    <ToggleButtonGroup
                        value={settings.type}
                        size="large"
                        fullWidth
                        color="primary"
                        onChange={(_, type) => {
                            if (!type || type === settings.type) return;

                            const newSettings = attributeInputs[activeInputLayer.type]?.all[activeInput.attribute]?.settings?.[type];
                            if (newSettings) {
                                updateActiveInput('settings', { ...newSettings, options: settings.options, label: activeInput.label });
                            }
                        }}
                        exclusive>
                        <ToggleButton value="radioList">
                            {Translation.get('dynamicLayers.layerEdit.editForms.inputTypes.radioList', 'template-designer')}
                        </ToggleButton>
                        <ToggleButton value="select">
                            {Translation.get('dynamicLayers.layerEdit.editForms.inputTypes.select', 'template-designer')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </InputWrapper>
            )}

            <InputWrapper label={Translation.get('sidebarRight.inputs.fontSource', 'template-designer')} gutterBottom>
                <Select margin="dense" value={fontSource} fullWidth onChange={(event) => setFontSource(event.target.value as FontType)}>
                    {Object.keys(fontSourceOptions).map((key) => (
                        <MenuItem key={key} value={key}>
                            {fontSourceOptions[key]}
                        </MenuItem>
                    ))}
                </Select>
            </InputWrapper>

            {fontSource === 'googleFonts' && (
                <InputWrapper label={Translation.get('sidebarRight.inputs.fontFamily', 'template-designer')}>
                    <AutocompleteImage
                        options={googleFontOptions}
                        multiple
                        value={
                            settings.options.map((font, index) => ({
                                value: font.key,
                                label: font.value,
                                image: `${process.env.STATIC_HOSTING_URL}assets/fonts/${encodeURI(font.key)}.svg`,
                                disabled: index === 0
                            })) as { value: string; label: string; image: string }[]
                        }
                        onChange={onChangeFont}
                    />
                </InputWrapper>
            )}
            {fontSource !== 'googleFonts' && (
                <InputWrapper label={Translation.get('sidebarRight.inputs.fontFamily', 'template-designer')}>
                    <Autocomplete
                        value={settings.options.map((font, index) => ({ value: font.key, label: font.value, disabled: index === 0 }))}
                        isFont
                        multiple
                        options={currentFontOptions}
                        onChange={onChangeFont}
                    />
                </InputWrapper>
            )}
        </div>
    );
};

export default FontFamilyForm;
