import React from 'react';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';

interface Props {
    disabled?: boolean;
    onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
}

const ExtraSettingsButton = ({ disabled, onClick }: Props) => {
    return (
        <IconButton disabled={disabled} onClick={onClick}>
            <Icon>settings</Icon>
        </IconButton>
    );
};

export { ExtraSettingsButton };
