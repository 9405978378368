import React from 'react';
import Translation from 'components/data/Translation';
import { AssetGalleryInputSettings } from 'components/template-designer/types/dynamicLayer.type';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { ExpandSection } from 'components/template-designer/components/ui-components/expand-section';
import { PopupPanel } from 'components/template-designer/components/ui-components/popup-panel';
import User from 'components/data/User';
import Setup from 'components/data/Setup';
import InputWrapper from 'components/ui-components-cape/InputWrapper';
import TextField from 'components/ui-components-v2/TextField';
import { SwitchSetting } from '../inputs/switch-setting';
import { ExtraSettingsButton } from '../inputs/extra-settings-button';
import { CollectionSetting } from '../inputs/collection-setting';
import '../styles/main.scss';

const AudioSettingsForm = (): JSX.Element | null => {
    const { settings, activeInput, activeInputLayer, updateInputSettings } = useDynamicLayers<AssetGalleryInputSettings>();

    if (!activeInput || !activeInputLayer) return null;

    return (
        <div className="template-designer__dynamic-layer-edit__media-settings-form">
            <ExpandSection
                expandSectionKey="inputSources"
                title={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.title', 'template-designer')}
                size="medium"
                disabled
                defaultOpen>
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.freeUpload', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.freeUploadAudioTooltip', 'template-designer')}
                    icon="upload"
                    value={settings.canUpload}
                    update={(newValue) => updateInputSettings('canUpload', newValue)}
                />
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrary', 'template-designer')}
                    tooltip={Translation.get(
                        'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibraryAudioTooltip',
                        'template-designer'
                    )}
                    icon="folder_open"
                    value={settings.canUseContentSpace}
                    update={(newValue) => updateInputSettings('canUseContentSpace', newValue)}>
                    <PopupPanel width={350} placement="bottom-end" clickAway button={<ExtraSettingsButton disabled={!settings.canUseContentSpace} />}>
                        {Setup.hasModule('mediaManagement') &&
                            User.hasRight(['assetManagerMediaWrite', 'assetManagerMediaRead', 'assetManagerMediaManagement']) && (
                                <CollectionSetting
                                    value={settings.contentSpaceCollectionQuery}
                                    onMutation={(newValue) => updateInputSettings('contentSpaceCollectionQuery', newValue, { useDebounce: true })}
                                    inputSize="medium"
                                />
                            )}
                        {Setup.hasModule('assetLibrary') && (
                            <InputWrapper
                                label={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrarySettings.collectionName',
                                    'template-designer'
                                )}
                                tooltip={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrarySettings.collectionNameTooltip',
                                    'template-designer'
                                )}>
                                <TextField
                                    size="small"
                                    value={settings.contentSpaceCollectionQuery}
                                    onChange={(event) => updateInputSettings('contentSpaceCollectionQuery', event.target.value, { useDebounce: true })}
                                />
                            </InputWrapper>
                        )}
                    </PopupPanel>
                </SwitchSetting>
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.url', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.urlAudioTooltip', 'template-designer')}
                    icon="link"
                    value={settings.canUseUrl}
                    update={(newValue) => updateInputSettings('canUseUrl', newValue)}
                />
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.voiceOverGenerator', 'template-designer')}
                    icon="record_voice_over"
                    value={settings.canUseVoiceOverGenerator}
                    update={(newValue) => updateInputSettings('canUseVoiceOverGenerator', newValue)}
                />
            </ExpandSection>

            <ExpandSection
                expandSectionKey="mediaManipulation"
                size="medium"
                disabled
                title={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.audioTitle', 'template-designer')}
                defaultOpen>
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.trimLayerLength', 'template-designer')}
                    icon="content_cut"
                    value={settings.useDurationOfLayer}
                    update={(newValue) => updateInputSettings('useDurationOfLayer', newValue)}
                />
            </ExpandSection>
        </div>
    );
};

export { AudioSettingsForm };
