import Layer from 'components/template-designer/types/layer.type';

import { InputAttribute } from '../../../types/attribute.type';
import { backgroundColor } from '../components/edit-forms/config/background-color';
import { shadowColor } from '../components/edit-forms/config/shadow-color';
import { borderColor } from '../components/edit-forms/config/border-color';
import { color } from '../components/edit-forms/config/color';
import { image } from '../components/edit-forms/config/image';
import { video } from '../components/edit-forms/config/video';
import { audio } from '../components/edit-forms/config/audio';
import { lottie } from '../components/edit-forms/config/lottie';
import { lineHeight } from '../components/edit-forms/config/line-height';
import { borderRadius } from '../components/edit-forms/config/border-radius';
import { borderWidth } from '../components/edit-forms/config/border-width';
import { fontSize } from '../components/edit-forms/config/font-size';
import { borderStyle } from '../components/edit-forms/config/border-style';
import { visibility } from '../components/edit-forms/config/visibility';
import { opacity } from '../components/edit-forms/config/opacity';
import { rotation } from '../components/edit-forms/config/rotation';
import { scale } from '../components/edit-forms/config/scale';
import { backgroundImage } from '../components/edit-forms/config/background-image';
import { showTextShadow } from '../components/edit-forms/config/show-text-shadow';
import { alignItems } from '../components/edit-forms/config/align-items';
import { objectPosition } from '../components/edit-forms/config/object-position';
import { positionX } from '../components/edit-forms/config/position-x';
import { positionY } from '../components/edit-forms/config/position-y';
import { justifyContent } from '../components/edit-forms/config/justify-content';
import { textDecoration } from '../components/edit-forms/config/text-decoration';
import { textDecorationStyle } from '../components/edit-forms/config/text-decoration-style';
import { frameDuration } from '../components/edit-forms/config/frame-duration';
import { flexDirection } from '../components/edit-forms/config/flex-direction';
import { rowGap } from '../components/edit-forms/config/row-gap';
import { columnGap } from '../components/edit-forms/config/column-gap';
import { fontFamily } from '../components/edit-forms/config/font-family';
import { textAlign } from '../components/edit-forms/config/text-align';
import { text } from '../components/edit-forms/config/text';
import { parseValueBg } from '../components/edit-forms/config/parse-value-bg';
import { parseValueImage } from '../components/edit-forms/config/parse-value-image';
import { feedSelectorInput } from '../components/edit-forms/config/feed-selector-input';
import { fadeAudio } from '../components/edit-forms/config/fade-audio';
import { fitToComposition } from '../components/edit-forms/config/fit-to-composition';
import { fillToComposition } from '../components/edit-forms/config/fill-to-composition';
import { muteAudio } from '../components/edit-forms/config/mute-audio';
import { file } from '../components/edit-forms/config/file';
import { frameAlignment } from '../components/edit-forms/config/frame-alignment';
import { frameFitting } from '../components/edit-forms/config/frame-fitting';
import { textJustification } from '../components/edit-forms/config/text-justification';
import { horizontalAlign } from '../components/edit-forms/config/horizontal-align';
import { verticalAlign } from '../components/edit-forms/config/vertical-align';
import { parseValueText } from '../components/edit-forms/config/parse-value-text';
import { divider } from '../components/edit-forms/config/divider';
import { alert } from '../components/edit-forms/config/alert';
import { group } from '../components/edit-forms/config/group';
import { dropdown } from '../components/edit-forms/config/dropdown';
import { customInput } from '../components/edit-forms/config/custom-input';
import { textBorderColor } from '../components/edit-forms/config/text-border-color';

export type AttributeInputs = {
    [K in Layer['type'] | 'format' | 'other']: {
        relevant?: Record<string, InputAttribute<unknown>>;
        all: Record<string, InputAttribute<unknown>>;
        //The inputs under superAdmin are only visible by super admin users and end up under all
        superAdmin?: Record<string, InputAttribute<unknown>>;
    };
};

const attributeInputs: AttributeInputs = {
    container: {
        relevant: {
            visibility,
            justifyContent,
            alignItems,
            flexDirection
        },
        all: {
            visibility,
            justifyContent,
            alignItems,
            flexDirection,
            rowGap,
            columnGap,
            backgroundColor,
            backgroundImage,
            opacity,
            scale,
            rotation,
            positionX,
            positionY,
            borderStyle,
            borderColor,
            borderWidth,
            shadowColor
        }
    },
    shape: {
        relevant: {
            visibility,
            backgroundImage,
            opacity,
            scale
        },
        all: {
            visibility,
            scale,
            horizontalAlign,
            verticalAlign,
            shadowColor,
            backgroundColor,
            backgroundImage,
            positionX,
            positionY,
            borderStyle,
            borderColor,
            borderWidth,
            borderRadius,
            opacity,
            rotation,
            parseValue: parseValueBg
        }
    },
    image: {
        relevant: {
            visibility,
            image
        },
        all: {
            visibility,
            image,
            scale,
            horizontalAlign,
            verticalAlign,
            shadowColor,
            backgroundColor,
            positionX,
            positionY,
            borderStyle,
            borderColor,
            borderWidth,
            borderRadius,
            opacity,
            rotation,
            objectPosition,
            parseValue: parseValueImage
        }
    },
    video: {
        relevant: {
            visibility,
            video
        },
        all: {
            visibility,
            video,
            scale,
            horizontalAlign,
            verticalAlign,
            shadowColor,
            backgroundColor,
            positionX,
            positionY,
            opacity,
            borderStyle,
            borderColor,
            borderWidth,
            rotation
        }
    },
    audio: {
        all: {
            audio
        }
    },
    text: {
        relevant: {
            visibility,
            text,
            color,
            fontSize,
            textAlign
        },
        all: {
            visibility,
            text,
            fontFamily,
            color,
            backgroundColor,
            positionX,
            positionY,
            fontSize,
            lineHeight,
            textAlign,
            textDecoration,
            textDecorationStyle,
            showTextShadow,
            opacity,
            textBorderColor,
            shadowColor,
            horizontalAlign,
            verticalAlign,
            borderStyle,
            borderColor,
            borderWidth,
            scale,
            rotation
        }
    },
    lottie: {
        relevant: {
            visibility,
            lottie,
            scale,
            rotation,
            opacity
        },
        all: {
            visibility,
            lottie,
            scale,
            horizontalAlign,
            verticalAlign,
            shadowColor,
            backgroundColor,
            backgroundImage,
            opacity,
            borderStyle,
            borderColor,
            borderWidth,
            rotation
        }
    },
    format: {
        relevant: {
            frameDuration
        },
        all: {
            frameDuration,
            borderStyle,
            borderColor,
            borderWidth,
            backgroundColor,
            backgroundImage
        }
    },
    // After Effects layer attributes.
    AE_image: { relevant: { visibility, image }, all: { visibility, image, scale, rotation, opacity, fitToComposition, fillToComposition } },
    AE_text: {
        relevant: { visibility, text, color, fontSize },
        all: { visibility, text, fontFamily, color, fontSize, textJustification, opacity, scale, parseValue: parseValueText }
    },
    AE_shape: { relevant: { visibility, scale }, all: { visibility, scale, backgroundColor, borderColor, borderWidth, rotation } },
    AE_video: {
        relevant: { visibility, video },
        all: { visibility, video, scale, rotation, opacity, muteAudio, fadeAudio, fitToComposition, fillToComposition }
    },
    AE_audio: { all: { visibility, audio, fadeAudio } },
    AE_misc: { all: { file } },
    // InDesign layer attributes.
    ID_Layer: { all: { visibility } },
    ID_Rectangle: {
        relevant: { visibility, backgroundColor },
        all: { visibility, borderColor, borderWidth, backgroundColor, image, frameAlignment, frameFitting }
    },
    ID_Polygon: { relevant: { visibility, backgroundColor }, all: { visibility, borderColor, borderWidth, backgroundColor } },
    ID_TextFrame: { relevant: { visibility, text, color }, all: { visibility, text, fontFamily, color, backgroundColor } },
    ID_Image: { relevant: { visibility, image }, all: { visibility, borderColor, borderWidth, backgroundColor, image, frameAlignment, frameFitting } },
    ID_PDF: { all: { visibility } },
    ID_Group: { all: { visibility } },
    other: { all: { divider, alert, group, dropdown, feedSelectorInput, customInput } }
};

export { attributeInputs };
