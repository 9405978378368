import React from 'react';
import Translation from 'components/data/Translation';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/dynamic-layers.context';
import { AlertSettings } from 'components/template-designer/types/dynamicLayer.type';
import InputWrapper from 'components/ui-components-cape/InputWrapper';
import TextField from 'components/ui-components-v2/TextField';
import Select from 'components/ui-components-v2/Select';
import MenuItem from 'components/ui-components-v2/MenuItem';
import '../styles/main.scss';

const AlertSettingsForm = (): JSX.Element | null => {
    const { settings, activeInput, updateInputSettings } = useDynamicLayers<AlertSettings>();

    if (!activeInput) return null;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form">
            <InputWrapper label={Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.message', 'template-designer')} gutterBottom>
                <TextField value={settings.message} onChange={(event) => updateInputSettings('message', event.target.value, { useDebounce: true })} fullWidth />
            </InputWrapper>

            <InputWrapper label={Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.alertType', 'template-designer')}>
                <Select
                    margin="dense"
                    value={settings.alertType}
                    fullWidth
                    onChange={(event) => updateInputSettings('alertType', event.target.value as AlertSettings['alertType'], { useDebounce: false })}>
                    <MenuItem value="info">
                        {Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.alertOptions.info', 'template-designer')}
                    </MenuItem>
                    <MenuItem value="alert">
                        {Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.alertOptions.warning', 'template-designer')}
                    </MenuItem>
                    <MenuItem value="success">
                        {Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.alertOptions.success', 'template-designer')}
                    </MenuItem>
                    <MenuItem value="error">
                        {Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.alertOptions.error', 'template-designer')}
                    </MenuItem>
                </Select>
            </InputWrapper>
        </div>
    );
};

export { AlertSettingsForm };
